
export default {
  name: 'PatternInput',
  props: {
    length: {
      type: Number,
      required: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      digits: Array(this.length).fill('')
    }
  },
  watch: {
    digits: {
      handler (newValue) {
        this.$emit('input', newValue.join(''))
      },
      deep: true
    },
    value: {
      handler (newValue) {
        if (!newValue) {
          this.digits = Array(this.length).fill('')
          return
        }
        const digits = newValue.split('')
        this.digits = [...digits, ...Array(this.length - digits.length).fill('')]
      },
      immediate: true
    }
  },
  methods: {
    handleInput (event, index) {
      const input = event.target
      const value = input.value

      if (!/^[0-9]$/.test(value)) {
        this.$set(this.digits, index, '')
        return
      }

      this.$set(this.digits, index, value)

      if (value && index < this.length - 1) {
        this.$nextTick(() => {
          this.$refs[`digit-${index + 1}`][0].focus()
        })
      }
    },
    handleKeydown (event, index) {
      if (event.key === 'Backspace' && !this.digits[index] && index > 0) {
        this.$refs[`digit-${index - 1}`][0].focus()
      }
      if (event.key === 'ArrowLeft' && index > 0) {
        event.preventDefault()
        this.$refs[`digit-${index - 1}`][0].focus()
      }
      if (event.key === 'ArrowRight' && index < this.length - 1) {
        event.preventDefault()
        this.$refs[`digit-${index + 1}`][0].focus()
      }
    },
    handlePaste (event) {
      event.preventDefault()
      const pastedData = (event.clipboardData || window.clipboardData)
        .getData('text')
        .replace(/[^0-9]/g, '')
        .slice(0, this.length)

      const digits = [...pastedData, ...Array(this.length - pastedData.length).fill('')]
      this.digits = digits

      const nextEmptyIndex = this.digits.findIndex(d => !d)
      const focusIndex = nextEmptyIndex === -1 ? this.length - 1 : nextEmptyIndex
      this.$nextTick(() => {
        this.$refs[`digit-${focusIndex}`][0].focus()
      })
    }
  }
}
